<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>
        Welcome
        <strong>{{
          this.user.name || "Guest, but wait... there's no guest!. Who are you then!?"
        }}</strong>
      </h2>
      <a-card class="mt-3" :key="componentKey">
        <lbbpr v-if="appnow === 'lbbpr'" />
        <lbbprs v-else-if="appnow === 'lbbprs'" />
        <slik v-else-if="appnow === 'slik'" />
        <default v-else />
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import lbbpr from './component/lbbpr-table'
import lbbprs from './component/lbbprs-table'
import slik from './component/slik-table'
import def from './component/default-table'

// import firebase from 'firebase/app'
import 'firebase/storage'

// import Docxtemplater from 'docxtemplater'
// import ImageModule from 'docxtemplater-image-module-free'
// import PizZip from 'pizzip'
// import { saveAs } from 'file-saver'
// import PizZipUtils from 'pizzip/utils/index.js'

import { mapState } from 'vuex'
export default {
  components: {
    lbbpr,
    lbbprs,
    slik,
    default: def,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  watch: {
    'settings.whatapp': {
      handler: function (newValue, oldValue) {
        // console.log('newValue', newValue.appName)
        // console.log('oldValue', oldValue.appName)
        var indexTarget = this.user.appList.findIndex(
          (x) => x.id === newValue.id,
        )
        // console.log('indexTarget', indexTarget)
        if (indexTarget >= 0) {
          // console.log('newValue.appName', newValue.appName)
          this.appnow = newValue.appName
        }
        // console.log('this.appnow', this.appnow)
        // this.componentKey += 1
      },
      deep: true,
    },
  },
  created() {
    // console.log('this.settings.whatapp', this.settings.whatapp)
    try {
      this.appnow = this.settings.whatapp.appName.toLowerCase()
      // console.log('this.appnow', this.appnow)
    } catch (e) {
      // console.log('e', e)
    }
    // console.log('this.settings.whatapp.appKey', this.settings.whatapp.appKey)
    if (this.settings.whatapp.appKey === 'gaji-newtemplate') {
      this.$router.push('/gaji/dashboard/view')
    } else if (this.settings.whatapp.appKey === 'school-management-key') {
      this.$router.push('/schoolmanagement/dashboard')
    } else if (this.settings.whatapp.appKey === 'bahari-biller') {
      this.getUid()
    }
    setTimeout(() => {
      this.componentKey += 1
    }, 50)
  },
  methods: {
    async getUid() {
      var res = await lou.customUrlGet2('uid')
      if (res) {
        // console.log('res.data.uid', res.data.uid)
        // console.log('this.settings.tempAppData.uid', this.settings.tempAppData.uid)
        this.settings.tempAppData = { uid: '', pin: '' }
        this.settings.tempAppData.uid = res.data === undefined || res.data === null ? '' : res.data.uid === undefined || res.data.uid === null ? '' : res.data.uid
        this.settings.tempAppData.pin = res.data === undefined || res.data === null ? '' : res.data.pin === undefined || res.data.pin === null ? '' : res.data.pin
      }
    },
  },
  data() {
    return {
      appnow: 'lbbprs',
      componentKey: 0,
    }
  },
}
</script>

<style>
.ps-container {
  overflow-y: auto !important;
}
.ps__rail-x {
  display: none !important;
}

.ps__rail-y {
  display: none !important;
}
</style>
